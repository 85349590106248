/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
import React, { useMemo } from "react";
import { Container } from "@mui/material";
import { terms, termsUa } from "../../data/terms";
import ApplyStyle from "../components/ApplyStyle";

function Terms() {
  // const { lang } = useMyContext();
  const lang = "en";

  // Ensure terms and terms[lang] are valid objects
  const selectedTerms = lang === "en" ? terms : termsUa;

  return (
    <Container sx={{ my: 7 }}>
      {Object.entries(selectedTerms).map(([sectionKey, sectionValue]) => {
        // Memoize the result of ApplyStyle for each section
        const renderedSection = useMemo(() => {
          return ApplyStyle(sectionKey, sectionValue);
        }, [sectionKey, sectionValue]);

        return <div key={sectionKey}>{renderedSection}</div>;
      })}
    </Container>
  );
}

export default Terms;
