/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ContactUs from "./modules/views/Contact";
import Video from "./modules/views/Main/Video";
import Instruction from "./modules/views/Main/Instruction";
import Best from "./modules/views/Main/Best";
import Buy from "./modules/views/Buy/Buy";
import About from "./modules/views/About";
import Partners from "./modules/views/Partners/index";
import AppFooter from "./modules/views/AppFooter";
import ProductHero from "./modules/views/Main/ProductHero";
import Product from "./modules/views/Main/Product";
import Margram from "./modules/views/Main/Margram";
import AppAppBar from "./modules/views/Header/AppAppBar";
import PrivacyPolicy from "./modules/views/PrivacyPolicy";
import Terms from "./modules/views/Terms";
import ReturnPolicy from "./modules/views/ReturnPolicy";
import Crewing from "./modules/views/Crewing";
import Docs from "./modules/views/Docs";

import MargramPage from "./modules/views/MargramPage";
import PrivacyPolicyMyDocs from "./modules/views/PrivacyPolicyMyDocs";

import ScrollButton from "./modules/components/ScrollButton";
import LoadingSpinner from "./modules/components/LoadingSpinner";
import useLoading from "./hooks/useLoading";

function Home() {
  const loading = useLoading();

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <AppAppBar />
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route path="/main" element={<Main />} />
          <Route path="/documents/*" element={<Docs />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/margram/*" element={<MargramPage />} />
          <Route path="/contact/*" element={<ContactUs />} />
          <Route path="/контакти/*" element={<ContactUs />} />
          <Route path="/about/*" element={<About />} />
          <Route path="/про%20нас/*" element={<About />} />
          <Route path="/buy/*" element={<Buy />} />
          <Route path="/купити/*" element={<Buy />} />
          <Route path="/privacy-policy/*" element={<PrivacyPolicy />} />
          <Route
            path="/my-documents/privacy-policy"
            element={<PrivacyPolicyMyDocs />}
          />
          <Route path="/terms-of-use/*" element={<Terms />} />
          <Route path="/return-policy/*" element={<ReturnPolicy />} />
          <Route path="/crewing/*" element={<Crewing />} />
        </Routes>
      </BrowserRouter>
      <ScrollButton />
      <AppFooter />
    </>
  );
}

export default Home;

function Main() {
  return (
    <>
      <ProductHero />
      <Margram />
      <Product />
      <Video />
      <Instruction />
      <Best />
    </>
  );
}
