import iosFree from "./iosfree";
import iosPro from "./iospro";
import androidPro from "./androidpro";
import androidFree from "./androidfree";
import mydocs from "./mydocs";

const getPolicy = (policyObj, lang) => {
  if (policyObj[lang]) {
    return policyObj[lang];
  }

  return "Policy not available in selected language";
};

const vPolicies = ({ lang }) => ({
  iosPro: {
    title: "IOS PRO",
    policy: getPolicy(iosPro.iosPro, lang),
  },
  iosFree: {
    title: "IOS FREE",
    policy: getPolicy(iosFree.iosFree, lang),
  },
  androidPro: {
    title: "Android PRO",
    policy: getPolicy(androidPro.androidPro, lang),
  },
  androidFree: {
    title: "Android FREE",
    policy: getPolicy(androidFree.androidFree, lang),
  },
  myDocuments: {
    title: "My Documets",
    policy: getPolicy(mydocs.mydocs, lang),
  },
});

export default vPolicies;
