import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import Typography from "../../../../components/Typography";
import Container from "../../../../components/Container";

function Hero() {
  useEffect(() => {
    const jarallaxInit = async () => {
      const jarallaxElems = document.querySelectorAll(".jarallax");
      if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
        return;
      }

      const { jarallax } = await import("jarallax");
      jarallax(jarallaxElems, { speed: 0.2 });
    };

    jarallaxInit();
  });

  const { t } = useTranslation();

  const header = t("docs.hero", { returnObjects: true });

  return (
    <Box
      className="jarallax"
      data-jarallax
      data-speed="0.2"
      position="relative"
      minHeight={{ xs: 400, sm: 500, md: 800 }}
      display="flex"
      alignItems="center"
      marginTop={-13}
      paddingTop={13}
      // id="agency__portfolio-item--js-scroll"
    >
      <Box
        className="jarallax-img"
        sx={{
          position: "absolute",
          objectFit: "cover",
          fontFamily: "object-fit: cover;",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundImage: "url(docs/3.png)",
          // filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
        }}
      />
      <Container position="relative" zIndex={2}>
        <Box>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <Typography
              variant="h2"
              marked="center"
              gutterBottom
              sx={{
                fontSize: { xs: "2.7rem", sm: "3.5rem", md: "5rem" },
                fontWeight: 900,
                color: "common.white",
                textAlign: "center",
              }}
            >
              {header.header1}
            </Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <Typography
              gutterBottom
              variant="h4"
              sx={{
                color: "common.black",
                fontWeight: 500,
                fontSize: { xs: "1.4rem", sm: "2.5rem" },
                textTransform: "none",
                textAlign: "center",
              }}
            >
              {header.text1}
            </Typography>
          </motion.div>
        </Box>
      </Container>
    </Box>
  );
}

export default Hero;
