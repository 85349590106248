import React from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
} from "@mui/material";
import { styled, keyframes, display } from "@mui/system";
import { motion } from "framer-motion";

import Typography from "../../../../components/Typography";

const GradientBorderBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  padding: 24,
  margin: 2,
  // border: "1px solid #ccc",
  display: "flex",
  // flexDirection: "row",
  alignItems: "center",
  // justifyContent: "center",
  height: "100%",
  width: "475px",
  // transition: "border 0.3s ease",
  // ":hover": {
  border: "1.5px solid",
  borderImageSlice: 1,
  animation: "pulsate-border 3s infinite linear",
  // },
  "@keyframes pulsate-border": {
    "0%": {
      borderImageSource: "linear-gradient(60deg, #4a678a, #95ccfc, #c21d64)",
    },
    "50%": {
      borderImageSource: "linear-gradient(60deg, #c21d64, #4a678a, #95ccfc)",
    },
    "100%": {
      borderImageSource: "linear-gradient(60deg, #95ccfc, #c21d64, #4a678a)",
    },
  },
}));

function Benefits() {
  const { t, i18n } = useTranslation();

  const benefits = t("docs.sec4", { returnObjects: true });

  return (
    <Container maxWidth="lg">
      {/* <Typography
          variant="h4"
          sx={{ my: 5, textAlign: "center", color: "warning.main" }}
        > */}
      <Typography
        variant={"h4"}
        sx={{
          fontWeight: 700,
          color: "warning.main",
          textAlign: "center",
          mb: 5,
          fontSize: { xs: "2.2rem", sm: "2.6rem", md: "3rem" },
        }}
        gutterBottom
      >
        {benefits.header6}
      </Typography>

      <Grid container spacing={5} justifyContent="center">
        {benefits.ul6.map((benefit, index) => (
          <Grid
            key={index}
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent="center"
          >
            <GradientBorderBox>
              {/* <Box sx={styles.number}>{index + 1}.</Box> */}
              <Box
                component="img"
                src={`/docs/done.gif`}
                alt={"done.gif"}
                sx={{
                  width: 125,
                  height: 125,
                }}
              />
              <Box sx={{ pl: 2, display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h4"
                  align="left"
                  sx={{ fontSize: "1.1rem", mb: 2 }}
                >
                  {benefit[1]}
                </Typography>
                <Typography variant="h5" align="left" sx={{ fontSize: "1rem" }}>
                  {benefit[2]}
                </Typography>
              </Box>
            </GradientBorderBox>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Benefits;
