/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Container } from "@mui/material";
import Typography from "../components/Typography";
import vPolicies from "../../data/vPolicies";
import ApplyStyle from "../components/ApplyStyle";

function PrivacyPolicy() {
  const lang = "en";
  const vers = "myDocuments";

  const policies = vPolicies({ lang });
  const licenseInfo = policies[vers]?.policy;

  if (!licenseInfo) {
    return (
      <Container sx={{ my: 7 }}>
        <Typography component="h2" variant="h2" marked="center" align="center">
          Privacy Policy
        </Typography>
        <div>
          Policy information not available for the selected version and
          language.
        </div>
      </Container>
    );
  }

  return (
    <Container sx={{ my: 7 }}>
      {/* <Typography component="h2" variant="h2" marked="center" align="center">
        Privacy Policy - My Documents
      </Typography> */}
      {Object.entries(licenseInfo).map(([sectionKey, sectionValue]) => (
        <div key={sectionKey}>{ApplyStyle(sectionKey, sectionValue)}</div>
      ))}
    </Container>
  );
}

export default PrivacyPolicy;
