import React from "react";
import { Box, Typography, Link, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CONTACTS } from "../../CONTACTS";

function Footer() {
  const apple = "/icons/app3.png";
  const google = "/icons/google.png";
  const insta = "/icons/instagram1.png";
  const youtube = "/icons/youtube1.png";
  const logo = "/images/Marine-Survey.png";
  const telegram = "/icons/telegram.png";

  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <Box
      minWidth="100%"
      py={4}
      sx={{ bgcolor: "secondary.main", boxShadow: 10 }}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} md={4}>
          <Box textAlign="center">
            <Link href="/">
              <img src={logo} alt="marine survey logo" width={350} />
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box textAlign="center">
            <Link href={CONTACTS.google} target="_blank">
              <img src={google} alt="icons" style={{ width: "280px" }} />
            </Link>
            <Link href={CONTACTS.appleFree} target="_blank">
              <img src={apple} alt="icons" style={{ width: "280px" }} />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box textAlign="center">
            <Link sx={{ p: 1 }} href={CONTACTS.insta}>
              <img
                src={insta}
                alt="insta-marine-survey"
                style={{ width: "50px" }}
              />
            </Link>
            <Link sx={{ p: 1 }} href={CONTACTS.youtube}>
              <img
                src={youtube}
                alt="youtube-marine-survey"
                style={{ width: "50px" }}
              />
            </Link>
            <Link sx={{ p: 1 }} href={CONTACTS.telegram}>
              <img
                src={telegram}
                alt="telegram-marine-survey"
                style={{ width: "50px" }}
              />
            </Link>
            {/* <Box textAlign="center" my={2}>
              <Typography sx={{ fontWeight: "bold", mb: -2 }}>
                {" "}
                {CONTACTS.req[lang][1]}
              </Typography>
              <br />
              <Typography variant="body2" align="center">
                {CONTACTS.req[lang][2]}
                <br />
                {CONTACTS.req[lang][3]}
                <br />
                {CONTACTS.req[lang][4]}
                <br />
                {CONTACTS.req[lang][5]}
                <br />
                {CONTACTS.req[lang][6]}
                <br />
                {CONTACTS.req[lang][7]}
              </Typography>
              <Box display="flex" justifyContent="space-evenly" mx={4}>
                <img
                  src="/icons/visa.png"
                  alt="Visa"
                  style={{ width: "auto", height: 50 }}
                />
                <img
                  src="/icons/mc.png"
                  alt="MasterCard"
                  style={{ width: "auto", height: 50 }}
                />
              </Box>
            </Box> */}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            textAlign: "center",
            mt: 2,
            borderTop: "1px solid #ccc",
            paddingTop: 2,
          }}
        >
          {" "}
          <Typography variant="body2">
            <Link href="/my-documents/privacy-policy" color="warning.main">
              My documents - Privacy Policy
            </Link>{" "}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            textAlign: "center",
            // mt: 2,
            // borderTop: "1px solid #ccc",
            // paddingTop: 2,
          }}
        >
          <Typography variant="body2">
            <Link href="/privacy-policy" color="inherit">
              {t("footer.1")}
            </Link>{" "}
            |{" "}
            <Link href="/terms-of-use" color="inherit">
              {t("footer.2")}
            </Link>{" "}
            |{" "}
            <Link href="/return-policy" color="inherit">
              {t("footer.3")}
            </Link>{" "}
          </Typography>
          <Typography
            variant="body2"
            mt={1.5}
            mb={-2}
            color="primary.main"
            textTransform="uppercase"
          >
            <Link
              href="https://www.bbqr.site/me"
              color="inherit"
              target="_blank"
            >
              Powered by Nataliaki
            </Link>{" "}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default React.memo(Footer);
