import React from "react";
import { Box, Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import List from "../../../../components/List";
import SlidingList from "../../../../components/SlidingList";
import { motion } from "framer-motion";

const Features = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid
        container
        spacing={4}
        flexDirection={isMd ? "row" : "column-reverse"}
      >
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 2.5, delay: 0.5 }}
          >
            <Box maxWidth={450} width={1}>
              <Box
                component={"img"}
                src="/docs/5.svg"
                alt="Image Description"
                sx={{
                  width: 1,
                  height: 1,
                }}
              />
            </Box>
          </motion.div>
        </Grid>
        <Grid
          item
          container
          alignItems={"center"}
          xs={12}
          md={6}
          // data-aos={"fade-up"}
        >
          <Box>
            <Box marginBottom={2}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
              >
                <Typography
                  variant={"h4"}
                  sx={{
                    fontWeight: 700,
                    color: "warning.main",
                    textAlign: { xs: "center", md: "left" },
                    fontSize: { xs: "2.2rem", sm: "2.6rem", md: "3rem" },
                  }}
                  gutterBottom
                >
                  {t("docs.features.header2")}
                </Typography>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: 200 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 2.5, delay: 0.9 }}
              >
                <Typography
                  sx={{ color: "common.white", mt: 2, fontWeight: "bold" }}
                  variant={"h6"}
                >
                  {t("docs.features.text2")}
                </Typography>
              </motion.div>
            </Box>

            <SlidingList
              items={t("docs.features.ul2", { returnObjects: true })}
              icon="./icons/arrow6.png"
            />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.5, delay: 1.5 }}
            >
              <Typography
                sx={{ color: "common.white", mt: 2, fontWeight: "bold" }}
                variant={"h6"}
              >
                {t("docs.features.bold2")}
              </Typography>
            </motion.div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Features;
