import React, { useRef, useEffect, useState, useMemo } from "react";
import { Grid } from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import Typography from "./Typography";
import { styled } from "@mui/system";

// Icon styling with a hover effect
const Icon = styled("img")(({ theme }) => ({
  width: "25px",
  position: "relative",
  "&:hover": {
    animation: "none",
    transform: "rotate(360deg)",
    transition: "transform 0.5s",
  },
}));

function SlidingList({ items = [], icon = null }) {
  const iconUrl = icon || "/icons/arrow6.png";
  const listRef = useRef(null);
  const imageRef = useRef(null);

  const [listInView, setListInView] = useState(false);
  const listAnimation = useAnimation();

  const parentControls = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0.5,
      },
    },
  };

  const childControls = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
  };

  // Use IntersectionObserver to check when elements are in view
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setListInView(true);
        }
      });
    });

    if (listRef.current) {
      observer.observe(listRef.current);
    }

    return () => {
      if (listRef.current) observer.unobserve(listRef.current);
    };
  }, []);

  useEffect(() => {
    if (listInView) {
      listAnimation.start("visible");
    }
  }, [listInView, listAnimation]);

  // Memoize the list items to avoid recalculating on each render
  const memoizedListItems = useMemo(
    () =>
      items.map((el, index) => (
        <motion.div
          key={index}
          variants={childControls}
          transition={{ duration: 1 }}
        >
          <Typography
            variant="h6"
            align="left"
            sx={{
              p: 1,
              transition: "transform 0.3s ease",
              display: "flex",
              alignItems: "center",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
          >
            <Icon src={iconUrl} sx={{ mr: 3 }} />
            {el}
          </Typography>
        </motion.div>
      )),
    [items, childControls, iconUrl],
  );

  return (
    <>
      <motion.div
        ref={listRef}
        initial="hidden"
        animate={listAnimation}
        variants={parentControls}
      >
        {memoizedListItems}
      </motion.div>
    </>
  );
}

export default SlidingList;
