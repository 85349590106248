/* eslint-disable quotes */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Grid, Box } from "@mui/material";
import { motion } from "framer-motion";
import { styled } from "@mui/system";
import Typography from "../../components/Typography";
import Types from "./Types";
import Process from "./Process";
import Faq from "./Faq";
import Video from "../Main/Video";
import Benefits from "./Benefits";

const GradientBorderBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  padding: 2,
  margin: 1,
  // border: "1px solid #ccc",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  // transition: "border 0.3s ease",
  ":hover": {
    border: "2.5px solid",
    borderImageSlice: 1,
    animation: "pulsate-border 3s infinite linear",
  },
  "@keyframes pulsate-border": {
    "0%": {
      borderImageSource: "linear-gradient(60deg, #4a678a, #95ccfc, #c21d64)",
    },
    "50%": {
      borderImageSource: "linear-gradient(60deg, #c21d64, #4a678a, #95ccfc)",
    },
    "100%": {
      borderImageSource: "linear-gradient(60deg, #95ccfc, #c21d64, #4a678a)",
    },
  },
}));

function PartnershipPage() {
  const { t } = useTranslation();

  const benefits = t("partners.benefits", { returnObjects: true });

  const styles = {
    image: {
      width: 65,
      height: 65,
      margin: "10px auto",
    },
  };

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Typography
            variant="h2"
            component="h1"
            marked="center"
            sx={{ my: 5, textAlign: "center" }}
          >
            {t("partners.title")}
          </Typography>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Typography
            variant="h4"
            component="h4"
            sx={{ mb: 1, color: "warning.main", textAlign: "center" }}
          >
            {t("partners.joinUs")}
          </Typography>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Typography variant="h6" sx={{ my: 5, textAlign: "center" }}>
            {t("partners.introduction")}
          </Typography>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 2.5, delay: 0.2 }}
        >
          <Typography
            variant="h4"
            component="h3"
            sx={{ textAlign: "center", color: "warning.main", mb: 2 }}
          >
            {t("partners.benefitsTitle")}
          </Typography>
        </motion.div>

        <Benefits />
        <Types />
        <Process />
        <Faq />
      </Container>
      <Video youtubeVideoId="fuK_vcu-nl8" buttonTitle="Play Video" />
    </>
  );
}

export default PartnershipPage;
