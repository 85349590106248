/* eslint-disable react/no-array-index-key */
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
} from "@mui/material";
import { motion } from "framer-motion";
import { keyframes } from "@mui/system";
import Typography from "../../../../components/Typography";

function PartnershipPage() {
  const { t, i18n } = useTranslation();
  const isUa = i18n.language === "ua";

  const shimmer = keyframes`
  0% {
    background-position: 1000px 0;
  }
  100% {
    background-position: -1000px 0;
  }
`;

  const types = t("docs.sec3", { returnObjects: true });

  const height = isUa ? "150px" : "110px";

  return (
    <Container maxWidth="lg">
      {/* New Section: Types of Partnership */}
      {/* <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 2.5, delay: 0.5 }}
      >
        <Typography
          variant="h4"
          sx={{ my: 5, textAlign: "center", color: "warning.main" }}
        >
          {partners.partnershipTitle}
        </Typography>
        <Typography variant="h6" sx={{ my: 5, textAlign: "center" }}>
          {partners.partnershipOptions}
        </Typography>
      </motion.div> */}

      <Grid container spacing={5} justifyContent="center">
        {types.map((type, index) => (
          <Grid
            key={index}
            item
            xs={12}
            md={4}
            display="flex"
            justifyContent="center"
          >
            <Card
              sx={{
                maxWidth: 345,
                minHeight: 630,
                background: "linear-gradient(60deg,#7caee5, #c21d64, #610e32)",
                color: "white",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  boxShadow: "0 10px 30px rgba(0,0,0,0.2)",
                  "&::before": {
                    animation: `${shimmer} 2s infinite linear`,
                  },
                },
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: "-50%",
                  left: "-50%",
                  width: "200%",
                  height: "200%",
                  background:
                    "linear-gradient(to right, transparent, rgba(255,255,255,0.1), transparent)",
                  transform: "rotate(30deg)",
                  transition: "all 0.3s ease-in-out",
                },
                overflow: "hidden",
                position: "relative",
              }}
            >
              <CardMedia
                component="img"
                // height={310}
                image={`/docs/sec3/${index + 1}.png`}
                alt={`image ${index}`}
              />
              <CardContent>
                <Box
                  sx={{
                    height: height,
                    display: "flex",
                    alignItems: "center",
                    mt: 1,
                    mb: 1.5,
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: "2rem",
                      textAlign: "center",
                      zIndex: 999,
                      color: "white",
                    }}
                  >
                    {type.header}
                  </Typography>
                  <Divider color="white" />
                </Box>
                <Divider color="white" />
                <Typography
                  sx={{
                    mt: 2,
                    fontSize: "1.3rem",
                    lineHeight: "1.5rem",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  {type.text}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default PartnershipPage;
