/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
import React from "react";
import {
  Link as RouterLink,
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import {
  Container,
  Link,
  Grid,
  ListItem,
  ListItemText,
  List as MUIList,
} from "@mui/material";
import List from "../components/List";
import Icon from "../components/Icon";
import Typography from "../components/Typography";
import vPolicies from "../../data/vPolicies";
import ApplyStyle from "../components/ApplyStyle";
import { useMyContext } from "../../MyContext";

function PrivacyPolicy() {
  // const { lang } = useMyContext();
  const lang = "en";
  return (
    <Routes>
      <Route path="/*" element={<PolicyLinks />} />
      <Route
        path="/ios-free"
        element={<PrivacyPolicyVersion vers="iosFree" lang={lang} />}
      />
      <Route
        path="/ios-pro"
        element={<PrivacyPolicyVersion vers="iosPro" lang={lang} />}
      />
      <Route
        path="/android-free"
        element={<PrivacyPolicyVersion vers="androidFree" lang={lang} />}
      />
      <Route
        exact
        path="/android-pro"
        element={<PrivacyPolicyVersion vers="androidPro" lang={lang} />}
      />
      <Route
        exact
        path="/my-documents"
        element={<PrivacyPolicyVersion vers="myDocuments" lang={lang} />}
      />
    </Routes>
  );
}

function PolicyLinks() {
  const path = "/privacy-policy";
  const policies = [
    {
      to: `${path}/android-free`,
      label: "Marine Survey Free Android",
    },
    { to: `${path}/android-pro`, label: "Marine Survey Pro Android" },
    { to: `${path}/ios-free`, label: "Marine Survey Assistant iOS" },
    { to: `${path}/ios-pro`, label: "Marine Survey Pro iOS" },
    // { to: `${path}/my-documents`, label: "Marine Survey My Documents" },
  ];
  return (
    <Container sx={{ my: 7 }}>
      <Typography component="h2" variant="h2" marked="center" align="center">
        Privacy Policies
      </Typography>
      <MUIList>
        {policies.map(({ to, label }) => (
          <ListItem key={to} component={RouterLink} to={to}>
            <Icon src="./icons/arrow6.png" alt="Arrow Marine Survey" />
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </MUIList>
    </Container>
  );
}

function PrivacyPolicyVersion({ vers, lang }) {
  const policies = vPolicies({ lang });
  const versionPolicies = policies[vers];
  const licenseInfo = versionPolicies ? versionPolicies.policy : null;

  if (!licenseInfo) {
    return (
      <div>
        Policy information not available for the selected version and language.
      </div>
    );
  }

  return (
    <Container sx={{ my: 7 }}>
      {Object.entries(licenseInfo).map(([sectionKey, sectionValue]) => (
        <div key={sectionKey}>{ApplyStyle(sectionKey, sectionValue)}</div>
      ))}
    </Container>
  );
}

export default PrivacyPolicy;
