/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { returnPolicy } from "../../data/returnPolicy";
import ApplyStyle from "../components/ApplyStyle";

function ReturnPolicy() {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <Container sx={{ my: 7 }}>
      {Object.entries(returnPolicy[lang]).map(([key, value]) => (
        <div key={key}>{ApplyStyle(key, value)}</div>
      ))}
    </Container>
  );
}

export default ReturnPolicy;
