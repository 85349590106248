/** @jsxImportSource react */
import React from "react";
import Box from "@mui/material/Box";

import Main from "../../components/layouts/Main";
import Container from "../../components/Container";
import {
  Folio,
  Hero,
  Services,
  Features,
  Gallery,
  Benefits,
  Conclusion,
} from "./components";

function PortfolioPage() {
  return (
    <>
      <Hero />
      <Box bgcolor="primary.light">
        <Container>
          <Features />
        </Container>
      </Box>
      <Container>
        <Folio />
      </Container>
      <Container>
        <Benefits />
      </Container>
      <Box bgcolor="warning.main">
        <Container>
          <Conclusion />
        </Container>
      </Box>
    </>
  );
}

export default PortfolioPage;
