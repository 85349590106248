import React, { useRef, useEffect, useState, useMemo } from "react";
import { Box, Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import List from "../../../../components/List";
import SlidingList from "../../../../components/SlidingList";
import { motion, useAnimation } from "framer-motion";

const Conclusion = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const conclusion = t("docs.sec5", { returnObjects: true });

  const imageRef = useRef(null);
  const [imageInView, setImageInView] = useState(false);
  const imageAnimation = useAnimation();

  const imageControls = {
    hidden: { opacity: 0, x: -200 },
    visible: {
      opacity: 1,
      x: -85,
      transition: { duration: 0.8, ease: "easeOut" },
    },
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setImageInView(true);
          }
        });
      },
      { threshold: 0.1 }, // Trigger when 10% of the element is visible
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) observer.unobserve(imageRef.current);
    };
  }, []);

  useEffect(() => {
    if (imageInView) {
      imageAnimation.start("visible");
    }
  }, [imageInView, imageAnimation]);
  return (
    <Box>
      <Grid
        container
        spacing={4}
        flexDirection={isMd ? "row" : "column-reverse"}
      >
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <motion.div
            ref={imageRef}
            initial="hidden"
            animate={imageAnimation}
            variants={imageControls}
          >
            {/* <motion.div
              variants={childControls}
              // initial={{ opacity: 0, x: -200 }}
              // animate={{ opacity: 1, x: -85 }}
              transition={{ duration: 3.5, delay: 3.5 }}
            > */}
            <Box maxWidth={750}>
              <Box
                component={"img"}
                src="/docs/10.png"
                alt="Conclusion image My Documents My Bridge Helper"
                sx={{
                  width: 800,
                  height: 500,
                }}
              />
            </Box>
            {/* </motion.div> */}
          </motion.div>
        </Grid>
        <Grid
          item
          container
          alignItems={"center"}
          xs={12}
          md={6}
          // data-aos={"fade-up"}
        >
          <Box>
            <Box marginBottom={2}>
              <Typography
                variant={"h4"}
                sx={{
                  fontWeight: 700,
                  color: "white",
                  textAlign: { xs: "center", md: "left" },
                  fontSize: { xs: "2.2rem", sm: "2.6rem", md: "3rem" },
                }}
                gutterBottom
              >
                {conclusion.header7}
              </Typography>
              <Typography
                sx={{ color: "common.white", mt: 2, fontWeight: "bold" }}
                variant={"h6"}
              >
                {conclusion.text7}
              </Typography>
            </Box>

            <Typography
              sx={{ color: "common.white", mt: 2, fontWeight: "bold" }}
              variant={"h6"}
            >
              {conclusion.conclusion}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Conclusion;
