import React from "react";
import {
  Container,
  Grid,
  Box,
  Icon,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Typography from "../../components/Typography";
import TitleWithCircles from "../../components/TitleWithCircles";

const Section = styled("section")(({ theme }) => ({
  padding: theme.spacing(8, 0),
  background: "#f8f9fc",
}));

// Styled Feature Item Card
const FeatureItem = styled("div")(({ theme }) => ({
  boxShadow: "0 10px 50px rgba(76, 76, 76, 0.05)",
  borderRadius: theme.shape.borderRadius,
  border: "1px solid transparent",
  background: "#fff",
  position: "relative",
  transition: "all 0.4s",
  zIndex: 3,
  "&:hover": {
    borderColor: "transparent",
    "&:before": {
      opacity: 1,
    },
  },
  "&:after, &:before": {
    content: '""',
    position: "absolute",
    borderRadius: theme.shape.borderRadius,
    background: "#fff",
    zIndex: -1,
  },
  "&:after": {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  "&:before": {
    top: -1,
    left: -1,
    right: -1,
    bottom: -1,
    backgroundImage: `linear-gradient(to left, ${theme.palette.primary.dark}, ${theme.palette.primary.light}, ${theme.palette.warning.main})`,
    opacity: 0,

    transition: "all 0.4s",
  },
}));

const features = {
  en: [
    {
      id: 1,
      image: "/images/margram/2/1.png",
      title: "Communication",
      details:
        "Communication between seafarers around the world. Ask questions and help others.",
    },
    {
      id: 2,
      image: "/images/margram/2/2.png",
      title: " New opportunities",
      details:
        "Sail into a world of new opportunities with MARGRAM: here every sailor is part of a big family!",
    },
    {
      id: 3,
      image: "/images/margram/2/3.png",
      title: " Sea World with MARGRAM",
      details:
        "Discover the maritime world with MARGRAM: connecting sailors in one wave!",
    },
    {
      id: 4,
      image: "/images/margram/2/4.png",
      title: "Crewing companies",
      details:
        "Opportunity for crewing companies to find seafarers in our huge community.",
    },
    {
      id: 5,
      image: "/images/margram/2/5.png",
      title: "Сonnections and knowledge",
      details:
        "MARGRAM is not just a social network, it is a community where every tide brings new connections and knowledge!",
    },
    {
      id: 6,
      image: "/images/margram/2/6.png",
      title: "Questions and Answers",
      details:
        "On MARGRAM, seamens find not only answers, but also friends throughout the wide world of seas.",
    },
  ],
  ua: [
    {
      id: 1,
      image: "/images/margram/2/1.png",
      title: "Комунікація",
      details:
        "Комунікація між моряками по всьому світу. Задавайте питання та допомагайте іншим.",
    },
    {
      id: 2,
      image: "/images/margram/2/2.png",
      title: "Нові можливості",
      details:
        "Пливіть у світ нових можливостей з MARGRAM: тут кожен моряк є частиною великої сім'ї!",
    },
    {
      id: 3,
      image: "/images/margram/2/3.png",
      title: "Морський світ з MARGRAM",
      details:
        "Відкрийте для себе морський світ з MARGRAM: об'єднуючи моряків на одній хвилі!",
    },
    {
      id: 4,
      image: "/images/margram/2/4.png",
      title: "Крюїнг-компанії",
      details:
        "Можливість для крюїнг-компаній знайти моряків у нашій великій спільноті.",
    },
    {
      id: 5,
      image: "/images/margram/2/5.png",
      title: "Зв'язки та знання",
      details:
        "MARGRAM — це не просто соціальна мережа, це спільнота, де кожна хвиля приносить нові зв'язки та знання!",
    },
    {
      id: 6,
      image: "/images/margram/2/6.png",
      title: "Питання та відповіді",
      details:
        "На MARGRAM моряки знаходять не лише відповіді, а й друзів по всьому широкому світу морів.",
    },
  ],
};

function AppServices() {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <Section>
      <Container>
        {/* <Grid container > */}
        {/* <Grid item lg={12} md={12}> */}

        {/* <TitleWithCircles sx={{ mb: 3, mt: 3 }}>Application Features</TitleWithCircles> */}
        <Typography variant="h3" sx={{ mb: 5, mt: -5, textAlign: "center" }}>
          Amazing Features of Margram
        </Typography>

        {/* </Grid> */}

        <Grid container spacing={3}>
          {features[lang].map((feature) => (
            <FeatureSection feature={feature} key={feature.id} />
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

function FeatureSection({ feature }) {
  return (
    <Grid item lg={4} md={6}>
      <FeatureItem>
        <CardMedia
          component="img"
          height="185"
          image={feature.image}
          alt={feature.title}
        />
        <CardContent>
          {/* <Typography gutterBottom variant="h5" component="div">
            {feature.title}
          </Typography> */}
          <Typography variant="body2" color="text.secondary">
            {feature.details}
          </Typography>
        </CardContent>
      </FeatureItem>
    </Grid>
  );
}

export default AppServices;
