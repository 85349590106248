import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Badge from "@mui/material/Badge";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {
  ListItemButton,
  ListItemText,
  Avatar,
  AppBar as MuiAppbar,
  Popover,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled, keyframes } from "@mui/system";
import { useTranslation } from "react-i18next";
import { CONTACTS } from "../../../CONTACTS";
import SocialLinks from "./SocialLinks";
import LanguageSwitcher from "./LanguageSwitcher";
import { useMyContext } from "../../../MyContext";
// import GradientBorder from "../../components/GradientBorderBox";

const GradientBorder = styled(Box)(({ theme }) => ({
  // Ensures content does not touch the animated border
  borderRadius: "10px",
  border: "1.5px solid",
  borderImageSlice: 1,
  animation: "pulsate-border 3s infinite linear",
  "@keyframes pulsate-border": {
    "0%": {
      borderImageSource: "linear-gradient(60deg, #4a678a, #95ccfc, #c21d64)",
    },
    "50%": {
      borderImageSource: "linear-gradient(60deg, #c21d64, #4a678a, #95ccfc)",
    },
    "100%": {
      borderImageSource: "linear-gradient(60deg, #95ccfc, #c21d64, #4a678a)",
    },
  },
  zIndex: 0,
}));

const rightLink = {
  fontSize: 13,
  color: "common.white",
  ml: 3,
};

const drawerWidth = "63%";

const navItems = {
  en: [
    "documents",
    "partners",
    "crewing",
    "margram",
    "about",
    "buy",
    "contact",
  ],
  ua: [
    "документи",
    "партнери",
    "crewing",
    "margram",
    "про нас",
    "купити",
    "контакти",
  ],
};

const uaToEnMapping = {
  margram: "margram",
  "про нас": "about",
  купити: "buy",
  контакти: "contact",
  crewing: "crewing",
  крюїнг: "crewing",
  партнери: "partners",
  документи: "documents",
};

const GradientAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "scrolled" && prop !== "isPC",
})(({ theme, scrolled, isPC }) => ({
  position: "fixed",
  transition: theme.transitions.create(["height", "background-color"], {
    duration: theme.transitions.duration.standard,
    easing: theme.transitions.easing.easeInOut,
  }),
  willChange: "height, background-color",
  height: scrolled ? 50 : 68,
  backgroundColor: scrolled || isPC ? "rgba(28,100,196, 0.3)" : "transparent",
  boxShadow: scrolled ? theme.shadows[2] : "none",
  backdropFilter: scrolled ? "blur(10px)" : "none",
}));

const AnimatedDrawer = styled(Drawer)(({ theme }) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.enter,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

function AppAppBar() {
  const { setLang, lang, isMobile } = useMyContext();

  const [drawerToggle, setDrawerToggle] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerToggle((prevState) => !prevState);
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setScrolled(scrollPosition > 80);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {CONTACTS.name}
      </Typography>
      <Divider />
      <List>
        {navItems[lang].map((item) => {
          const hrefItem = lang === "ua" ? uaToEnMapping[item] : item;
          return item === "margram" ||
            item === "crewing" ||
            item === "крюїнг" ||
            item === "partners" ||
            item === "documents" ||
            item === "партнери" ? (
            <ListItem key={item}>
              <ListItemButton
                sx={{
                  textAlign: "center",
                  textTransform: "uppercase",
                  justifyContent: "center",
                }}
                component="a"
                href={`/${hrefItem}`}
              >
                <Badge
                  badgeContent="NEW"
                  color="secondary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <GradientBorder>
                    <ListItemText
                      primary={item}
                      sx={{
                        color: "#1c64c4",
                        textAlign: "center",
                        textTransform: "uppercase",
                        alignItems: "center",
                        px: 2,
                      }}
                    />
                  </GradientBorder>
                </Badge>
              </ListItemButton>
            </ListItem>
          ) : (
            <ListItem key={item}>
              <ListItemButton
                sx={{ textAlign: "center", textTransform: "uppercase" }}
                component="a"
                href={`/${hrefItem}`}
              >
                <ListItemText primary={item} sx={{ color: "#1c64c4" }} />
              </ListItemButton>
            </ListItem>
          );
        })}
        <Divider />
        <LanguageSwitcher sx={{ my: 2 }} />
      </List>

      <SocialLinks />
    </Box>
  );

  return (
    <div>
      <GradientAppBar scrolled={scrolled} isPC={!isMobile}>
        <Toolbar
          sx={{
            justifyContent: "space-between",
            mt: scrolled ? -1 : 0,
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
          }}
        >
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            href="/"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: scrolled ? "0.9rem" : "1.2rem",
            }}
          >
            <Avatar
              src="/favicon.png"
              sx={{
                mr: 1,
                width: scrolled ? 35 : 45,
                height: scrolled ? 35 : 45,
                zIndex: 1300,
              }}
            />
            {!isMobile && `${CONTACTS.name}`}
          </Link>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {!isMobile ? (
              <>
                {navItems[lang].map((item, i) => {
                  const hrefItem = lang === "ua" ? uaToEnMapping[item] : item;
                  return item === "margram" ||
                    item === "partners" ||
                    item === "партнери" ||
                    item === "documents" ||
                    item === "crewing" ||
                    item === "крюїнг" ? (
                    <Badge
                      key={i}
                      badgeContent="NEW"
                      color="secondary"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <GradientBorder sx={{ ml: 3.2 }}>
                        <Link
                          variant="h6"
                          underline="none"
                          href={`/${hrefItem}`}
                          sx={{ ...rightLink, mr: 2, py: 0.25 }}
                        >
                          {item}
                        </Link>
                      </GradientBorder>
                    </Badge>
                  ) : (
                    <Link
                      key={i}
                      variant="h6"
                      underline="none"
                      href={`/${hrefItem}`}
                      sx={rightLink}
                    >
                      {item}
                    </Link>
                  );
                })}
                <LanguageSwitcher sx={{ my: 2 }} />
              </>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, zIndex: 1300 }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </Toolbar>
      </GradientAppBar>
      <nav>
        <AnimatedDrawer
          open={drawerToggle}
          anchor="right"
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "secondary.main",
            },
          }}
        >
          {drawer}
        </AnimatedDrawer>

        {drawerToggle && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(227,219,164, 0.5)",
              zIndex: 1000,
            }}
            onClick={handleDrawerToggle}
          />
        )}
      </nav>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
