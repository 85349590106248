exports.mydocs = {
  en: {
    title: "MY DOCUMENTS PRIVACY POLICY",
    subtitle1: "Last update: 07.10.2024.",
    subtitle1bold: "",
    text1:
      "We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines the types of data we collect, how we use and safeguard it, and the rights you have concerning your information when you use our mobile application, My Documents (hereinafter referred to as 'the App').\n By using the App, you agree to the collection and use of information in accordance with this Privacy Policy.",
    subtitle2: "1. Information We Do Not Collect",
    subtitle2bold:
      "We do not collect, store, or process any of your personal documents or data, including but not limited to:",
    ul2: [
      "Personal identification documents (e.g., passports, national IDs)",
      "Financial information (e.g., credit cards, bank account details)",
      "Passwords or authentication information",
      "Documents stored within the App, such as PDFs, images, Word files, Excel spreadsheets, or other forms of data.",
    ],
    text2:
      "All documents and files that you upload or create within the App are stored locally on your device. We do not have access to, nor do we transmit, these documents to any external servers or third parties.",
    subtitle3: "2. Backup and Cloud Services",
    subtitle3bold:
      "The App offers an option to back up your documents and data using Google Drive, which is a third-party cloud service. When you choose to back up or restore data:",
    text3: null,
    subtitle4bold: "Information obtained from other sources.",
    ul4: [
      "Backup Data: A backup file containing your documents is generated on your device and uploaded to your personal Google Drive account.",
      "Restoring Data: You can restore your documents from your Google Drive backup to your device.",
    ],
    text4:
      "We do not have access to your Google Drive account or the data stored there. All data management within Google Drive is subject to Google’s own privacy policy and terms of service, which you should review.",
    subtitle5: "3. Account Information",
    subtitle5bold: null,
    text5:
      "The App may require the use of a Google account to access the backup and restore functionalities on Google Drive. We do not collect or store any personal information related to your Google account. The use of Google account credentials is managed through the Google Authentication system, and no personal account details are stored by the App.",
    ul5: null,
    subtitle6: "4. Permissions Required",
    subtitle6bold:
      "To function effectively, the App may require the following device permissions:",
    ul6: [
      "Storage Access: To save and manage documents stored locally on your device.",
      "Internet Access: To allow for the backup and restore functionalities to Google Drive. ",
    ],
    text6:
      " These permissions are only used for the purposes outlined above, and we do not collect any information from these permissions unless explicitly stated.",
    subtitle7: "5. Security of Your Data",
    text7:
      "We understand that the security of your data is crucial. We take reasonable measures to ensure that your data remains secure on your device and during the backup and restore processes. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.",
    subtitle7bold: "We encourage users to:",
    ul7: [
      "Use strong passwords and two-factor authentication for their Google accounts.",
      "Regularly update their devices and the App to benefit from the latest security enhancements.",
    ],
    text7ab: null,
    text7a: null,
    subtitle8: "6. Third-Party Services",
    subtitle8bold: null,
    text8:
      "The App integrates with Google Drive, a third-party service, for backup and restoration purposes. We do not control and are not responsible for the privacy practices of Google. We encourage you to review Google’s privacy policy to understand how your data is handled when using their services. \n Google’s Privacy Policy can be found https://policies.google.com/privacy .",
    subtitle9: "7. Children's Privacy",
    subtitle9bold: null,
    text9:
      "The App is not intended for use by children under the age of 13. We do not knowingly collect personally identifiable information from children. If we discover that a child under 13 has provided us with personal information, we will delete such information immediately. If you are a parent or guardian and believe your child has used the App without your consent, please contact us so that we can take appropriate action.",
    textItalic9: null,
    subtitle10: "8. Changes to This Privacy Policy",
    subtitle10bold: null,
    text10:
      "We may update our Privacy Policy from time to time. When we do, we will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.",
    subtitle11: "9. Files cookie",
    subtitle11bold: null,
    text11:
      "We may use cookies, web beacons, tags, scripts, local shared objects such as HTML5, and advertising identifiers (including mobile identifiers such as Apple's IDFA or Google's Advertising ID) and similar technologies (“Cookies”) in connection with using Website or the App for certain purposes: Your authentication, remembering your settings and other purposes specified below. \n A cookie is a small piece of data that is sent to the browser on your computer or mobile device. Cookies belonging to the Website or the App are essential. Cookies that we allow other organizations to send through our websites are called 'optional cookies'. \n Additionally, a distinction is made between session cookies and persistent cookies. Session cookies last until you close your browser or the App. In the permanent file ah Cookies have a longer lifespan: they are not automatically deleted when you close your browser. \n 'Software Development Kits ' (SDKs) function like pixels and cookies, but operate in a mobile application environment where pixels and cookies may not always function. The host program developer may install code snippets (SDKs) from partners in the application and thereby allow the partner to collect certain information about the user's interaction with the program and information about the user's devices and network information. \n Cookies are used for different purposes. They help to recognize you as the same user on different pages of the Site or when using the App.",
    subtitle12bold: null,
    text12: null,
    textItalic12: "The App use cookies for various purposes:",
    ul12: [
      "Technical cookies: We want our users to experience modern, user-friendly sites and applications that automatically adapt to their needs. We use technical cookies to ensure that the Website displays correctly and functions without errors, and to enable you to create, log in to and manage your orders. Technical cookies are necessary to ensure error-free operation of the Website and the App.",
      "Functional cookies: they are necessary to remember your preferences and help you use the Website and the App.",
      "Analytical cookies: they are necessary in order to find out how visitors use the Site or the App. We can understand how well various functions work, optimize the performance of the Website and the App, analyze advertising and communications. Analytical cookies help us make sure that the Site and our extensive applications are interesting and useful for users.",
    ],
    text12a:
      "This also means that we may use data about how you interact with the Website, such as the number of times you click on a page, mouse and scroll movements, search words and other text you enter in various fields. \n Website users using your operating system, you can choose whether you allow cookies to be used or whether you want to share your advertising ID with us or our advertising service providers. \n If you do not want your personal data to be collected through the use of cookies on a website, you can disable cookies by adjusting your internet browser settings to turn off, block or disable cookies, deleting your browsing history and clearing your internet cache. browser. You can also limit the sharing of some of this data through your mobile device settings.",
    subtitle14: "10. Your Rights",
    subtitle14bold: null,
    ul14: null,
    text16:
      "Because we do not collect any of your personal data, there is no data for you to access, modify, or delete within our system. However, you retain full control over your documents and backups stored locally on your device or on Google Drive. You may delete or modify these files at any time directly from your device or Google Drive account.",
    textItalic15: null,
    subtitle13: "11. Contact Us",
    subtitle13bold: "How to contact us?",
    text13:
      "Questions, comments and requests regarding the Privacy Statement are welcomed and directed to our customer service team {support@mybridgehelper.com}. If you believe that we have violated this Privacy Statement, please contact us and let us know what exactly was violated by this Privacy Statement. We will promptly investigate your complaint.",
    text14: "The updated version is valid from {07/10/2024}.",
    textItalicUnderline1:
      "By using the App, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.",
  },
  ua: {
    title: "MY DOCUMENTS PRIVACY POLICY",
    subtitle1: "Last update: 07.10.2024.",
    subtitle1bold: "",
    text1:
      "We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines the types of data we collect, how we use and safeguard it, and the rights you have concerning your information when you use our mobile application, My Documents (hereinafter referred to as 'the App').\n By using the App, you agree to the collection and use of information in accordance with this Privacy Policy.",
    subtitle2: "1. Information We Do Not Collect",
    subtitle2bold:
      "We do not collect, store, or process any of your personal documents or data, including but not limited to:",
    ul2: [
      "Personal identification documents (e.g., passports, national IDs)",
      "Financial information (e.g., credit cards, bank account details)",
      "Passwords or authentication information",
      "Documents stored within the App, such as PDFs, images, Word files, Excel spreadsheets, or other forms of data.",
    ],
    text2:
      "All documents and files that you upload or create within the App are stored locally on your device. We do not have access to, nor do we transmit, these documents to any external servers or third parties.",
    subtitle3: "2. Backup and Cloud Services",
    subtitle3bold:
      "The App offers an option to back up your documents and data using Google Drive, which is a third-party cloud service. When you choose to back up or restore data:",
    text3: null,
    subtitle4bold: "Information obtained from other sources.",
    ul4: [
      "Backup Data: A backup file containing your documents is generated on your device and uploaded to your personal Google Drive account.",
      "Restoring Data: You can restore your documents from your Google Drive backup to your device.",
    ],
    text4:
      "We do not have access to your Google Drive account or the data stored there. All data management within Google Drive is subject to Google’s own privacy policy and terms of service, which you should review.",
    subtitle5: "3. Account Information",
    subtitle5bold: null,
    text5:
      "The App may require the use of a Google account to access the backup and restore functionalities on Google Drive. We do not collect or store any personal information related to your Google account. The use of Google account credentials is managed through the Google Authentication system, and no personal account details are stored by the App.",
    ul5: null,
    subtitle6: "4. Permissions Required",
    subtitle6bold:
      "To function effectively, the App may require the following device permissions:",
    ul6: [
      "Storage Access: To save and manage documents stored locally on your device.",
      "Internet Access: To allow for the backup and restore functionalities to Google Drive. ",
    ],
    text6:
      " These permissions are only used for the purposes outlined above, and we do not collect any information from these permissions unless explicitly stated.",
    subtitle7: "5. Security of Your Data",
    text7:
      "We understand that the security of your data is crucial. We take reasonable measures to ensure that your data remains secure on your device and during the backup and restore processes. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.",
    subtitle7bold: "We encourage users to:",
    ul7: [
      "Use strong passwords and two-factor authentication for their Google accounts.",
      "Regularly update their devices and the App to benefit from the latest security enhancements.",
    ],
    text7ab: null,
    text7a: null,
    subtitle8: "6. Third-Party Services",
    subtitle8bold: null,
    text8:
      "The App integrates with Google Drive, a third-party service, for backup and restoration purposes. We do not control and are not responsible for the privacy practices of Google. We encourage you to review Google’s privacy policy to understand how your data is handled when using their services. \n Google’s Privacy Policy can be found https://policies.google.com/privacy .",
    subtitle9: "7. Children's Privacy",
    subtitle9bold: null,
    text9:
      "The App is not intended for use by children under the age of 13. We do not knowingly collect personally identifiable information from children. If we discover that a child under 13 has provided us with personal information, we will delete such information immediately. If you are a parent or guardian and believe your child has used the App without your consent, please contact us so that we can take appropriate action.",
    textItalic9: null,
    subtitle10: "8. Changes to This Privacy Policy",
    subtitle10bold: null,
    text10:
      "We may update our Privacy Policy from time to time. When we do, we will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.",
    subtitle11: "9. Files cookie",
    subtitle11bold: null,
    text11:
      "We may use cookies, web beacons, tags, scripts, local shared objects such as HTML5, and advertising identifiers (including mobile identifiers such as Apple's IDFA or Google's Advertising ID) and similar technologies (“Cookies”) in connection with using Website or the App for certain purposes: Your authentication, remembering your settings and other purposes specified below. \n A cookie is a small piece of data that is sent to the browser on your computer or mobile device. Cookies belonging to the Website or the App are essential. Cookies that we allow other organizations to send through our websites are called 'optional cookies'. \n Additionally, a distinction is made between session cookies and persistent cookies. Session cookies last until you close your browser or the App. In the permanent file ah Cookies have a longer lifespan: they are not automatically deleted when you close your browser. \n 'Software Development Kits ' (SDKs) function like pixels and cookies, but operate in a mobile application environment where pixels and cookies may not always function. The host program developer may install code snippets (SDKs) from partners in the application and thereby allow the partner to collect certain information about the user's interaction with the program and information about the user's devices and network information. \n Cookies are used for different purposes. They help to recognize you as the same user on different pages of the Site or when using the App.",
    subtitle12bold: null,
    text12: null,
    textItalic12: "The App use cookies for various purposes:",
    ul12: [
      "Technical cookies: We want our users to experience modern, user-friendly sites and applications that automatically adapt to their needs. We use technical cookies to ensure that the Website displays correctly and functions without errors, and to enable you to create, log in to and manage your orders. Technical cookies are necessary to ensure error-free operation of the Website and the App.",
      "Functional cookies: they are necessary to remember your preferences and help you use the Website and the App.",
      "Analytical cookies: they are necessary in order to find out how visitors use the Site or the App. We can understand how well various functions work, optimize the performance of the Website and the App, analyze advertising and communications. Analytical cookies help us make sure that the Site and our extensive applications are interesting and useful for users.",
    ],
    text12a:
      "This also means that we may use data about how you interact with the Website, such as the number of times you click on a page, mouse and scroll movements, search words and other text you enter in various fields. \n Website users using your operating system, you can choose whether you allow cookies to be used or whether you want to share your advertising ID with us or our advertising service providers. \n If you do not want your personal data to be collected through the use of cookies on a website, you can disable cookies by adjusting your internet browser settings to turn off, block or disable cookies, deleting your browsing history and clearing your internet cache. browser. You can also limit the sharing of some of this data through your mobile device settings.",
    subtitle14: "10. Your Rights",
    subtitle14bold: null,
    ul14: null,
    text16:
      "Because we do not collect any of your personal data, there is no data for you to access, modify, or delete within our system. However, you retain full control over your documents and backups stored locally on your device or on Google Drive. You may delete or modify these files at any time directly from your device or Google Drive account.",
    textItalic15: null,
    subtitle13: "11. Contact Us",
    subtitle13bold: "How to contact us?",
    text13:
      "Questions, comments and requests regarding the Privacy Statement are welcomed and directed to our customer service team {support@mybridgehelper.com}. If you believe that we have violated this Privacy Statement, please contact us and let us know what exactly was violated by this Privacy Statement. We will promptly investigate your complaint.",
    text14: "The updated version is valid from {07/10/2024}.",
    textItalicUnderline1:
      "By using the App, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.",
  },
};
